import React from 'react';
import { shape, string } from 'prop-types';

import { Layout, SEO, Container } from 'layout';
import Discuss from 'components/form/discuss';

const DiscussPage = ({ pageContext: { locale } }) => (
  <Layout lang={locale}>
    <SEO lang={locale} />

    <Container className="page">
      <Discuss />
    </Container>
  </Layout>
);

DiscussPage.propTypes = {
  pageContext: shape({
    locale: string.isRequired,
  }).isRequired,
};

export default DiscussPage;
